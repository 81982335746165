// import ReactGA from 'react-ga';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { formatCurrency } from './currency';

// Google Analytics
export const initGA = () => {
  try {
    ReactGA.initialize(process.env.GOOGLE_TRACKING);
    // Loads the Linker plugin
    ReactGA.ga('require', 'linker');
    // Instructs the Linker plugin to automatically add linker parameters
    // to all links and forms pointing to the domain "eatchefly.com".
    ReactGA.ga('linker:autoLink', ['eatchefly.com'], false, true);
  } catch (e) {
    console.log('error', e);
  }
};

export const logPageView = () => {
  try {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send('pageview');
  } catch (e) {
    console.log('error', e);
  }
};

export const logEvent = (category = '', action = '', value) => {
  try {
    if (category && action) {
      ReactGA.event({ category, action, value });
    }
  } catch (e) {
    console.log('error', e);
  }
};

export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};

export const logFacebookEvent = (category = '', action = '', additionalProp = {}, eventProp = {}) => {
  try {
    if (process.env.FACEBOOK_PIXEL_ID && process.env.NODE_ENV === 'production' && category && action) {
      window.fbq(category, action, additionalProp, eventProp);
    }
  } catch (e) {
    console.log('Facebook error:', e);
  }
};

export const logKlaviyoEvent = (category = '', action = '', additionalProps = {}) => {
  try {
    // if (process.env.NODE_ENV === 'production' && category) {
    // eslint-disable-next-line no-underscore-dangle
    window._learnq.push([category, action, JSON.stringify(additionalProps)]);
  } catch (e) {
    console.log('KLAVIYO ERROR', e);
  }
  // }
};

export const logKlaviyoEventViaAPI = (eventName = '', customerProperties = {}, properties = {}) => {
  // const data = JSON.stringify({
  //   token: 'QSeu6J',
  //   event: eventName,
  //   customer_properties: { $email: email },
  //   properties,
  // });

  // const options = {
  //   method: 'POST',
  //   headers: { Accept: 'text/html', 'Content-Type': 'application/x-www-form-urlencoded' },
  //   body: new URLSearchParams({
  //     data,
  //     // data: `{"token": "QSeu6J", "event": "${eventName}", "customer_properties": {"$email": "${email}"}, "properties": {"coupon": "Boots","$value": 100}}`,
  //   }),
  // };

  // fetch('https://a.klaviyo.com/api/track', options)
  //   .then((response) => response.json())
  //   .then((response) => console.log(response))
  //   .catch((err) => console.error(err));

  const data = JSON.stringify({
    token: 'QSeu6J',
    event: eventName,
    customer_properties: customerProperties,
    properties,
  });

  axios({
    method: 'post',
    url: 'https://a.klaviyo.com/api/track',
    headers: { Accept: 'text/html', 'Content-Type': 'application/x-www-form-urlencoded' },
    data: new URLSearchParams({
      data,
    }),
  })
    .then((response) => console.log(response))
    .catch((err) => console.error(err));
};

export const logTikTokEvent = (action = '', additionalProps = {}) => {
  try {
    // if (process.env.NODE_ENV === 'production' && category) {
    // eslint-disable-next-line no-underscore-dangle
    window.ttq.track(action, additionalProps);
  } catch (e) {
    console.log('TIKTOK ERROR', e);
  }
  // }
};

export const logTradeDoublerEvent = ({ orderNumber, orderValue, voucherCode = '' } = {}) => {
  try {
    if (window.tdconv) {
      window.tdconv('init', '2313979', { element: 'iframe' });
      window.tdconv('track', 'sale', {
        transactionId: String(orderNumber),
        ordervalue: formatCurrency(orderValue),
        voucher: voucherCode,
        currency: 'GBP',
        event: 421726,
      });
    }
  } catch (e) {
    console.log('TradeDoubler ERROR', e);
  }
  // }
};

export const logTapfiliateEvent = ({ chargeId, amount, stripeCustomerId }) => {
  try {
    if (window.tap) {
      window.tap('create', '30776-4af89e', { integration: 'stripe' });
      window.tap('conversion', chargeId, amount, { customer_id: stripeCustomerId });
    }
  } catch (e) {
    console.log('Tapfiliate error:', e);
  }
};

export const logWebgainEvent = ({ orderId, orderValue, voucherCode = '' }) => {
  try {
    if (window.ITCVRQ) {
      window.ITCVRQ('set', 'trk.programId', 297120);
      window.ITCVRQ('set', 'cvr', {
        value: orderValue / 100,
        currency: 'GBP',
        language: 'UK_en',
        eventId: 1089210,
        orderReference: orderId,
        comment: '',
        multiple: '',
        checksum: '',
        items: '',
        customerId: '',
        voucherId: voucherCode,
        location: document.location.href,
      });
      window.ITCVRQ('conversion');
    }
  } catch (e) {
    console.log('Webgain error:', e);
  }
};
