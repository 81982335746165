import styled from 'styled-components';
import { motion } from 'framer-motion';
import Image from 'components/Image';
import { device } from 'styles/device';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1220px;
`;

export const Icon = styled(Image)`
  margin: ${({ margin }) => margin && `${margin}`};
  width: ${({ size }) => (size ? `${size}px` : `auto`)};

  @media ${device.laptop} {
    width: ${({ sizeLg }) => (sizeLg ? `${sizeLg}px` : `auto`)};
  }
`;

export const FlexRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
`;

export const FlexCol = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const H2 = styled.h2`
  font-size: 20px;
  font-weight: normal;
  @media ${device.laptop} {
    font-size: 34px;
  }
`;

export const Subtitle = styled.p`
  font-size: 20px;
  @media ${device.laptop} {
    font-size: 26px;
  }
`;

export const Text = styled(motion.p)`
  font-family: 'paralucent-light', sans-serif;
  font-size: 14px;
  font-weight: ${({ weight }) => weight};
  @media ${device.laptop} {
    font-size: 20px;
  }
`;

export const AnimatedText = styled.div`
  font-family: 'paralucent-light', sans-serif;
  font-size: 14px;
  font-weight: ${({ weight }) => weight};
  @media ${device.laptop} {
    font-size: 20px;
  }
`;

export const StyledUnderLine = styled(motion.hr)`
  background-color: ${({ primary, theme }) => (primary ? theme.orange : theme.green)};
  border: none;
  height: 0.125em;
  position: absolute;
  transform: rotate(-1deg);
  width: 100%;
`;

export const StyledUnderLineCurve = styled(motion.div)`
  position: relative;

  svg {
    height: auto;
    left: 0;
    position: absolute;
    top: -7px;
    width: 100%;
  }
`;

export const UnderlinedText = styled(motion.div)`
  display: inline-block;
  font-family: 'paralucent-medium', sans-serif;
  position: relative;
  width: fit-content;
`;

export const InputNotification = styled(motion.div).attrs(() => ({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
}))`
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 28px;
  text-align: center;
  ${({ theme, error }) =>
    error &&
    `
  color:${theme.red}`};
  ${({ truncate }) =>
    truncate &&
    `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`};
`;

export const Label = styled.span`
  background-color: ${({ status, theme }) => {
    switch (status) {
      case 'paid':
        return theme.darkGreen;
      case 'pending':
        return theme.turquoise;
      case 'refunded':
        return theme.red;
      default:
        return theme.darkGreen;
    }
  }};
  border-radius: 4px;
  color: white;
  font-family: 'paralucent-light', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 6px 14px;

  @media ${device.laptop} {
  }
`;
