import PropTypes from 'prop-types';
import { StyledButton } from './style';

const Button = ({ rightIcon, leftIcon, secondary, dangerous, children, iconStyles, ...props }) => {
  return (
    <StyledButton
      whileHover={{
        scale: 0.95,
        // borderRadius: '100%',
      }}
      whileTap={{
        scale: 0.9,
        // borderRadius: '100%',
      }}
      secondary={secondary}
      dangerous={dangerous}
      {...props}
    >
      {leftIcon && (
        <span
          style={{
            display: 'flex',
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translate(0%, -50%)',
            ...iconStyles,
          }}
        >
          {leftIcon}
        </span>
      )}
      {children}
      {rightIcon && (
        <span
          style={{
            display: 'flex',
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translate(0%, -50%)',
            ...iconStyles,
          }}
        >
          {rightIcon}
        </span>
      )}
    </StyledButton>
  );
};

Button.propTypes = {
  secondary: PropTypes.bool,
  dangerous: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  iconStyles: PropTypes.object,
  rightIcon: PropTypes.element,
  leftIcon: PropTypes.element,
};

export default Button;
