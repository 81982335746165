import Prismic from 'prismic-javascript';

export const apiEndpoint = process.env.PRISMIC_API_ENDPOINT;
export const accessToken = '';

const createClientOptions = (req = null, prismicAccessToken = null) => {
  const reqOption = req ? { req } : {};
  const accessTokenOption = prismicAccessToken ? { accessToken: prismicAccessToken } : {};
  return {
    ...reqOption,
    ...accessTokenOption,
  };
};

export const linkResolver = (doc) => {
  if (doc.type === 'homepage') return '/';
  if (doc.type === 'plans') return '/plans';
  if (doc.type === 'menu') return '/menu';
  if (doc.type === 'help') return '/help';
  // Add here future routes
  return '/';
};

// Additional helper function for Next/Link component
export const hrefResolver = (doc) => {
  if (doc.type === 'homepage') return '/';
  if (doc.type === 'plans') return '/plans';
  if (doc.type === 'menu') return '/menu';
  if (doc.type === 'generic-page') {
    return '/[slug]';
  }
  return '/';
};

// Client method to query documents from the Prismic repo
export const Client = (req = null) => Prismic.client(apiEndpoint, createClientOptions(req, accessToken));
