import PropTypes from 'prop-types';
import { StyledImage } from './style';

const Image = ({ className, src, alt = '', ...rest }) => (
  <StyledImage className={className} src={src} alt={alt} loading="lazy" decoding="async" {...rest} />
);

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default Image;
