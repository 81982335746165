import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const MotionBox = styled(motion.div)`
  width: 100%;
`;

const FadeInUpBoxPages = ({
  children,
  yOffset = 24, // y initial position
  easing = [0.42, 0, 0.58, 1], // [number, number, number, number] | "linear" | "easeIn" |
  //  "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" |
  // "backInOut" | "anticipate" | EasingFunction;
  delay = 0,
  duration,
  ...rest
}) => {
  const transition = useMemo(
    () => ({
      duration,
      delay,
      ease: easing,
    }),
    [delay, easing, duration]
  );

  const variants = {
    hidden: { y: yOffset, opacity: 0, transition },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition,
    },
    exit: { opacity: 0, transition },
  };

  return (
    <MotionBox initial="hidden" animate="show" exit="exit" variants={variants} {...rest}>
      {children}
    </MotionBox>
  );
};

FadeInUpBoxPages.propTypes = {
  children: PropTypes.element.isRequired,
  delayOrder: PropTypes.number,
  delay: PropTypes.number,
  duration: PropTypes.number,
  easing: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
  yOffset: PropTypes.number,
};

export default FadeInUpBoxPages;
