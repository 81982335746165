import { useEffect } from 'react';
import { initGA, logPageView } from 'utils/analytics';

const useGoogleAnalytics = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (!window.GA_INITIALIZED) {
        initGA();
        window.GA_INITIALIZED = true;
      }
      logPageView();
    }
  }, []);
};
export default useGoogleAnalytics;
