import styled from 'styled-components';
import { motion } from 'framer-motion';
import { device } from 'styles/device';

export const StyledButton = styled(motion.button)`
  align-items: center;
  background-color: ${({ secondary, theme }) => (secondary ? theme.white : theme.yellow)};
  border: 2px solid ${({ theme }) => theme.yellow};
  border-radius: 8px;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 35px;
  justify-content: center;
  outline: none;
  position: relative;
  transition: 0.2s background-color;
  user-select: none;
  width: 213px;

  &:active {
    background-color: ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
    border: 2px solid ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
  }

  &:disabled {
    /* background-color: #dddddd; */
    opacity: 0.4;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
      border: 2px solid ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
    }
  }

  @media ${device.laptop} {
    height: 50px;
    width: 232px;
    font-size: 18px;
    border: 2px solid ${({ theme }) => theme.yellow};
  }

  ${(p) =>
    p.dangerous &&
    `
     background-color: ${p.theme.white};
     color: ${p.theme.red};
     border: 2px solid ${p.theme.red};

     &:active {
        background-color: ${p.theme.white};
        border: 2px solid ${p.theme.red};
      }

      @media (hover: hover) {
        &:hover {
          background-color:  ${p.theme.red};
          color: ${p.theme.white};
          border: 2px solid  ${p.theme.red};
        }
      }

      @media ${device.laptop} {
        border: 2px solid ${p.theme.red};
      }
  `}
`;

export const ButtonLink = styled(motion.a)`
  align-items: center;
  background-color: ${({ secondary, theme }) => (secondary ? theme.white : theme.yellow)};
  border: 2px solid ${({ theme }) => theme.yellow};
  /* border: none; */
  border-radius: 8px;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 35px;
  justify-content: center;
  outline: none;
  transition: 0.2s background-color;
  user-select: none;
  width: 213px;

  &:active {
    background-color: ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
    border: 2px solid ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
  }

  &:disabled {
    background-color: #dddddd;
    opacity: 0.4;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
      border: 2px solid ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
    }
  }

  @media ${device.laptop} {
    height: 50px;
    width: 232px;
    font-size: 18px;
    border: 2px solid ${({ theme }) => theme.yellow};
  }
`;
